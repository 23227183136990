import { Match, Switch, lazy } from "solid-js";

import { useTranslation } from "#root/domain/i18n";

const English = lazy(() => import("#root/content/privacy/english.mdx"));
const French = lazy(() => import("#root/content/privacy/french.mdx"));

export default function Privacy() {
  const { locale } = useTranslation();

  return (
    <Switch>
      <Match when={locale === "fr"}>
        <French />
      </Match>

      <Match when={locale === "en"}>
        <English />
      </Match>
    </Switch>
  );
}
